import React from 'react';
import styles from './SponsorLogo.module.scss';
import ImageTag from '../../../../../../shared/ImageTag/ImageTag';
import { Anchor } from '../../../../../../shared/Anchor';

export interface SponsorLogoProps {
  src: string;
  alt: string;
  openInNewTab?: boolean;
  url?: string;
}

export function SponsorLogo({ src, alt, openInNewTab, url }: SponsorLogoProps) {
  const logoContent = (
    <div className={styles.logo}>
      <ImageTag src={src} alt={alt} />
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.heading}>Sponsored by</div>
      {url ? (
        <Anchor to={url} openInNewTab={openInNewTab}>
          {logoContent}
        </Anchor>
      ) : (
        <>{logoContent}</>
      )}
    </div>
  );
}
