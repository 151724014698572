import React, { Component } from 'react';
import PropTypes from 'prop-types';

const defaultOptions = {
  autoPlay: true,
  muted: true,
  controlsVisibleOnLoad: false,
  wmode: 'transparent',
};

export class WistiaEmbed extends Component {
  componentDidMount() {
    if (this.props.videoId) {
      this.insertWistiaScript();
    }
  }

  insertWistiaScript() {
    const { videoId, onReady, ...embedOptions } = this.props;

    window._wq = window._wq || [];
    window._wq.push({
      id: videoId,
      onReady,
      options: Object.assign({}, defaultOptions, embedOptions),
    });

    if (!document.getElementById('wistia_script')) {
      const wistiaScript = document.createElement('script');
      wistiaScript.async = true;
      wistiaScript.id = 'wistia_script';
      wistiaScript.type = 'text/javascript';
      wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';

      document.body.appendChild(wistiaScript);
    }
  }

  render() {
    const { videoId } = this.props;

    return (
      <div
        className={`wistia_embed wistia_async_${videoId} videoFoam=true embed-responsive-item`}
      />
    );
  }
}

WistiaEmbed.propTypes = {
  videoId: PropTypes.string.isRequired,
  onReady: PropTypes.func,
};

export default WistiaEmbed;
