import {
  ChangeEventHandler,
  KeyboardEventHandler,
  SyntheticEvent,
  useCallback,
  useState,
} from 'react';
import { UseFormReturn } from 'react-hook-form';
import cn from 'classnames';
import styles from './KeywordSearchAlt.module.scss';
import { PropsWithTestId } from '../../../../../../types';
import { KeywordSearchProps } from '../KeywordSearch';
import { useKeywordSearchSuggestions } from '../../../../../../hooks';
import { useDocumentClick } from '../../../../../../hooks/useDocumentClick';
import { HubDiscoveryValues } from '../../../../../../store/features/discovery';
import { DiscoverySuggestion } from '../../../../../../store/features/discoverySuggestions';
import SearchSuggestions from '../KeywordSearch/SearchSuggestions';

export interface KeywordSearchAltProps
  extends Omit<KeywordSearchProps, 'theme' | 'form'> {
  form?: UseFormReturn<HubDiscoveryValues>;
  enableSuggestions?: boolean;
}

export const defaultProps = {
  testId: 'keyword-search-alt',
};

export default function KeywordSearchAlt(
  props: PropsWithTestId<KeywordSearchAltProps>,
) {
  const {
    value,
    setValue,
    handleChange,
    handleKeyDown,
    handleSearch,
    handleReset,
    onResetValue,
    form,
    enableSuggestions,
    testId,
    ...inputProps
  } = props;

  const handleSelectSuggestion = useCallback(
    (suggestion: DiscoverySuggestion) => {
      const suggestionTitle = suggestion.title;

      setValue(suggestionTitle);
      handleSearch(suggestionTitle);
    },
    [handleSearch, setValue],
  );

  const {
    open: suggestionsOpen,
    setOpen: setSuggestionsOpen,
    setCursorIndex: setSuggestionCursorIndex,
    fetchAction: fetchSuggestions,
    disableSearchOnEnter,
    ...keywordSearchSuggestionsProps
  } = useKeywordSearchSuggestions(handleSelectSuggestion, form);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    handleChange(event);

    if (enableSuggestions) {
      const searchValue = event.target.value;

      if (searchValue) {
        fetchSuggestions(searchValue);
      } else {
        setSuggestionsOpen(false);
      }
    }
  };

  const handleInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (enableSuggestions && disableSearchOnEnter) return;

    handleKeyDown(e);
  };

  const handleSearchValue = () => {
    handleSearch(value);
    setSuggestionsOpen(false);
  };

  const [suggestionsActive, setSuggestionsActive] = useState(false);

  const componentRef = useDocumentClick(suggestionsOpen, () => {
    setSuggestionCursorIndex(-1);
    setSuggestionsActive(false);
  });

  const handleInputFocus = () => {
    setSuggestionsActive(true);
  };

  const handleComponentClick = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  const wrapperClassName = cn(styles.wrapper, {
    [styles.showSuggestions]: suggestionsOpen && suggestionsActive,
  });

  return (
    <div
      ref={componentRef}
      onClick={handleComponentClick}
      className={wrapperClassName}
      data-testid={testId}
    >
      <div className={styles.inputWrapper}>
        <label htmlFor="keyword-search-alt" className={styles.startButton}>
          <span className={cn('material-icons', styles.icon)}>search</span>
        </label>
        <input
          type="text"
          value={value}
          onInput={handleInputChange}
          onKeyDown={handleInputKeyDown}
          onFocus={handleInputFocus}
          id="keyword-search-alt"
          {...inputProps}
        />
        <div
          onClick={handleSearchValue}
          className={cn(styles.endButton, {
            [styles.activeButton]: value,
          })}
          role="button"
        >
          <span className={cn('material-icons', styles.icon)}>east</span>
        </div>
      </div>
      {suggestionsOpen && suggestionsActive && (
        <div className={styles.suggestionsWrapper}>
          <SearchSuggestions
            value={value}
            className={styles.suggestions}
            {...keywordSearchSuggestionsProps}
          />
        </div>
      )}
    </div>
  );
}

KeywordSearchAlt.defaultProps = defaultProps;
