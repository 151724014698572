import cn from 'classnames';
import styles from './BrowseEventsLink.module.scss';

export interface BrowseEventsLinkProps {
  text: string;
  onClick: () => void;
}

export function BrowseEventsLink({ text, onClick }: BrowseEventsLinkProps) {
  return (
    <div onClick={onClick} className={styles.wrapper}>
      <div className={styles.link}>{text}</div>
      <div className={styles.arrowButton}>
        <span className={cn('material-icons', styles.arrowIcon)}>
          arrow_downward
        </span>
      </div>
    </div>
  );
}
