import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import cn from 'classnames';
import {
  HubModule,
  HubModuleCTA,
  PropsWithTestId,
  UploadedImage,
} from '../../../../../../types';
import { HubModuleTypes } from '../../../../../../constants';
import { concatPath } from '../../../../../../helpers';
import { useRootSelector } from '../../../../../../store/hooks';
import {
  useGetImgixImageUrl,
  useKeywordSearch,
  useShowBackgroundVideo,
} from '../../../../../../hooks';
import { useHubContext } from '../../../context/hubContext';
import { HubDiscoveryValues } from '../../../../../../store/features/discovery';
import { selectAudienceHubPrimaryTopic } from '../../../../audienceHub/store/features/options';
import styles from './HeroModule.module.scss';
import WistiaEmbed from '../../../../../shared/WistiaEmbed/WistiaEmbed';
import { HubModuleWrapper } from '../../HubModuleWrapper';
import { BackgroundSlider } from './BackgroundSlider';
import { RightImage } from './RightImage';
import { PreTitle } from './PreTitle';
import { ValueProposition } from './ValueProposition';
import { Text } from './Text';
import { SponsorLogo } from './SponsorLogo';
import { KeywordSearch } from '../../components';
import KeywordSearchAlt from '../../components/KeywordSearchAlt';
import { BrowseEventsLink } from './BrowseEventsLink';
import { CallToActionGroup } from '../../../CallToActionGroup';
import { BrandLogo } from './BrandLogo';

export enum HeroModuleDisplayStyles {
  Image = 'IMAGE',
  MultipleImages = 'MULTIPLE_IMAGES',
  LargeImageRight = 'IMAGE_RIGHT',
  SmallImageRight = 'SMALL',
  Video = 'VIDEO',
}

export interface HeroModuleType extends HubModule {
  '@type': HubModuleTypes.Hero;
  preTitle: string;
  valueProposition: string;
  subTitle: string;
  text: string;
  displayStyle: HeroModuleDisplayStyles;
  carouselOptions: 'NONE' | 'SLIDE' | 'FADE';
  carouselImages: Array<{
    image: UploadedImage;
    alt: string;
  }>;
  image: UploadedImage;
  brandLogo: UploadedImage;
  brandLogoAlt: string;
  videoId: string;
  sponsorLogo: UploadedImage;
  sponsorLogoURL: string;
  sponsorLogoAlt: string;
  openSponsorInNewTab: boolean;
  displayKeywordSearch: boolean;
  keywordSearchTitle: string;
  displayBrowseEventsLink: boolean;
  browseEventsLinkTitle: string;
  browseEventsLinkURL: string;
  primaryLink: HubModuleCTA;
  secondaryLink: HubModuleCTA;
}

export interface HeroModuleProps {
  module: HeroModuleType;
  home?: boolean;
}

export default function HeroModule(props: PropsWithTestId<HeroModuleProps>) {
  const {
    module: {
      '@type': type = HubModuleTypes.Hero,
      displayStyle,
      carouselOptions,
      carouselImages,
      image,
      brandLogo,
      brandLogoAlt,
      videoId,
      preTitle,
      valueProposition,
      text,
      sponsorLogo,
      sponsorLogoURL,
      sponsorLogoAlt,
      openSponsorInNewTab,
      displayKeywordSearch,
      keywordSearchTitle,
      displayBrowseEventsLink,
      browseEventsLinkTitle,
      browseEventsLinkURL,
      primaryLink,
      secondaryLink,
      elementId,
      visualStyle,
    },
    home,
    testId,
  } = props;

  const { indexPath, theme, brandName } = useHubContext();
  const mainFilterTopic = useRootSelector(selectAudienceHubPrimaryTopic);
  const form = useForm<HubDiscoveryValues>({
    defaultValues: {
      subBrands: [brandName],
      mainFilterTopic,
    },
  });

  const searchHandler = useCallback(
    (searchValue: string) => {
      const value = encodeURIComponent(searchValue);
      const url = concatPath([indexPath, '/search/']);
      document.location.assign(`${url}?searchInput=${value}`);
    },
    [indexPath],
  );

  const { ...keywordSearchProps } = useKeywordSearch(searchHandler);

  const browseEventsHandler = useCallback(() => {
    const navElement = document.getElementById('hub-nav');
    const destinationElement = document.getElementById(browseEventsLinkURL);

    if (destinationElement) {
      const nav = navElement?.offsetHeight || 60;
      const destination = destinationElement.offsetTop;

      window.scrollTo({
        top: destination - nav,
        behavior: 'smooth',
      });
    }
  }, [browseEventsLinkURL]);

  const IMAGE = displayStyle === HeroModuleDisplayStyles.Image;
  const MULTIPLE_IMAGES =
    displayStyle === HeroModuleDisplayStyles.MultipleImages;
  const LARGE_IMAGE = displayStyle === HeroModuleDisplayStyles.LargeImageRight;
  const SMALL_IMAGE = displayStyle === HeroModuleDisplayStyles.SmallImageRight;
  const VIDEO = displayStyle === HeroModuleDisplayStyles.Video;

  const showBackgroundVideo = useShowBackgroundVideo(videoId);
  const showRightImage = LARGE_IMAGE || SMALL_IMAGE;

  const imageURL = useGetImgixImageUrl(image?.path);
  const [backgroundImage, setBackgroundImage] = useState(() => {
    return (IMAGE || VIDEO) && imageURL ? `url(${imageURL})` : undefined;
  });

  const normalizedDisplayStyle = displayStyle.toLowerCase().replace(/_/g, '-');
  const displayStyleClassName = `hub-hero-module-${normalizedDisplayStyle}-display-style`;

  const handleRemoveBGImageOnVideoReady = useCallback(() => {
    setBackgroundImage(undefined);
  }, []);

  return (
    <HubModuleWrapper
      type={type}
      theme={visualStyle}
      elementId={elementId}
      className={cn(styles.wrapper, 'lazy', displayStyleClassName, {
        [styles.smallImageWrapper]: SMALL_IMAGE,
        'hub-home-hero-module': home,
      })}
      overlay={
        <>
          {MULTIPLE_IMAGES && (
            <BackgroundSlider
              slides={carouselImages}
              animation={carouselOptions}
            />
          )}
          {(IMAGE || VIDEO || MULTIPLE_IMAGES) && (
            <div
              data-testid="hero-module-wistia-embed"
              className={cn(styles.overlay, 'hub-hero-module-overlay')}
            >
              {VIDEO && showBackgroundVideo && (
                <WistiaEmbed
                  videoId={videoId}
                  endVideoBehavior="loop"
                  plugin={{
                    cropFill: {
                      src: '//fast.wistia.com/labs/crop-fill/plugin.js',
                    },
                  }}
                  onReady={handleRemoveBGImageOnVideoReady}
                />
              )}
            </div>
          )}
        </>
      }
      home={home}
      style={{ backgroundImage }}
      testId={testId}
    >
      <div className="container">
        <div className="row middle-md">
          <div className="col-xs-12 col-sm-8 col-md-6 col-lg-7">
            <div className={styles.body}>
              {brandLogo && (
                <div className={styles.brandLogo}>
                  <BrandLogo src={brandLogo?.path} alt={brandLogoAlt} />
                </div>
              )}
              {preTitle && (
                <div className={styles.preTitle}>
                  <PreTitle text={preTitle} />
                </div>
              )}
              {valueProposition && (
                <div className={styles.valueProposition}>
                  <ValueProposition
                    text={valueProposition}
                    size={SMALL_IMAGE ? 'smaller' : 'normal'}
                    tag={home ? 'h1' : 'h2'}
                  />
                </div>
              )}
              {text && (
                <div className={styles.text}>
                  <Text text={text} />
                </div>
              )}
              {sponsorLogo && (
                <div className={styles.sponsorLogo}>
                  <SponsorLogo
                    src={sponsorLogo.path}
                    url={sponsorLogoURL}
                    alt={sponsorLogoAlt}
                    openInNewTab={openSponsorInNewTab}
                  />
                </div>
              )}
              {displayKeywordSearch && (
                <div className={styles.keywordSearch}>
                  {keywordSearchTitle && (
                    <div className={styles.keywordSearchTitle}>
                      {keywordSearchTitle}
                    </div>
                  )}
                  {/* Element to discuss in case amount of variants increase */}
                  {home && theme === 'INFORMACONNECT' ? (
                    <KeywordSearchAlt
                      {...keywordSearchProps}
                      form={form}
                      enableSuggestions
                    />
                  ) : (
                    <KeywordSearch
                      {...keywordSearchProps}
                      form={form}
                      enableSuggestions={home}
                    />
                  )}
                </div>
              )}
              {displayBrowseEventsLink && (
                <div className={styles.browseEventsLink}>
                  <BrowseEventsLink
                    text={browseEventsLinkTitle}
                    onClick={browseEventsHandler}
                  />
                </div>
              )}
              <CallToActionGroup
                primary={primaryLink}
                secondary={secondaryLink}
                className={styles.ctaGroup}
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-6 col-lg-5">
            {showRightImage && image && (
              <div className={styles.rightImage}>
                <RightImage image={image} />
              </div>
            )}
          </div>
        </div>
      </div>
    </HubModuleWrapper>
  );
}

HeroModule.defaultProps = {
  home: false,
  testId: 'hub-hero-module',
};
