import React from 'react';
import styles from './Text.module.scss';
import { HTMLtoJSX } from '../../../../../../../helpers';

export interface TextProps {
  text: React.ReactNode;
}

export function Text({ text }: TextProps) {
  return <div className={styles.text}>{HTMLtoJSX(text)}</div>;
}
