import React from 'react';
import cn from 'classnames';
import { HTMLtoJSX, removeTagsFromString } from '../../../../../../../helpers';
import styles from './ValueProposition.module.scss';

export enum ValuePropositionSizes {
  normal = 'normal',
  smaller = 'smaller',
}

export interface ValuePropositionProps {
  text: React.ReactNode;
  size: keyof typeof ValuePropositionSizes;
  tag: 'h1' | 'h2';
}

export function ValueProposition(props: ValuePropositionProps) {
  const { text, size, tag: Tag } = props;

  return (
    <Tag className={cn(styles.heading, styles[size])}>
      {HTMLtoJSX(removeTagsFromString(text, ['br']))}
    </Tag>
  );
}

ValueProposition.defaultProps = {
  size: ValuePropositionSizes.normal,
  tag: 'h2',
};
