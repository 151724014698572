import React from 'react';
import styles from './BrandLogo.module.scss';
import ImageTag from '../../../../../../shared/ImageTag/ImageTag';

export interface SponsorLogoProps {
  src: string;
  alt: string;
}

export function BrandLogo({ src, alt }: SponsorLogoProps) {
  return (
    <div className={styles.logo}>
      <ImageTag src={src} alt={alt} />
    </div>
  );
}
