import React from 'react';
import styles from './PreTitle.module.scss';

export interface PreTitleProps {
  text: React.ReactNode;
}

export function PreTitle({ text }: PreTitleProps) {
  return <h4 className={styles.heading}>{text}</h4>;
}
